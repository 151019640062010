import Vue from "vue";
// import axios from "axios";
import VueCookies from "vue-cookies";
Vue.use(VueCookies);

export const state = () => ({
  selectedAgent: {
    selected: false,
    note: {
      private: {
        adminName: null,
        chatroomId: null,
        createTime: "0001-01-01T00:00:00",
        note: null,
        id: null,
        type: 2,
        updateTime: "0001-01-01T00:00:00",
        updateUser: null,
        userGUID: null
      },
      public: {
        adminName: null,
        chatroomId: null,
        createTime: "0001-01-01T00:00:00",
        note: null,
        id: null,
        type: 1,
        updateTime: "0001-01-01T00:00:00",
        updateUser: null,
        userGUID: null
      }
    }
  }
});

export const mutations = {
  UPDATE_AGENT_NOTE_EMPTY: state => {
    for (let index = 1; index <= 2; index++) {
      let keyName = "public";
      if (index == 2) keyName = "private";

      state.selectedAgent.note[keyName].adminName = null;
      state.selectedAgent.note[keyName].chatroomId = null;
      state.selectedAgent.note[keyName].id = null;
      state.selectedAgent.note[keyName].type = 1;
      state.selectedAgent.note[keyName].updateUser = null;
      state.selectedAgent.note[keyName].userGUID = null;
      state.selectedAgent.note[keyName].note = null;
      state.selectedAgent.note[keyName].createTime = "0001-01-01T00:00:00";
      state.selectedAgent.note[keyName].updateTime = "0001-01-01T00:00:00";
    }
  },
  UPDATE_AGENT_NOTE: (state, payload) => {
    let keyName = "public";
    if (payload.type == 2) keyName = "private";

    state.selectedAgent.note[keyName].adminName = payload.adminName;
    state.selectedAgent.note[keyName].chatroomId = payload.chatroomId;
    state.selectedAgent.note[keyName].id = payload.id;
    state.selectedAgent.note[keyName].type = payload.type;
    state.selectedAgent.note[keyName].updateUser = payload.updateUser;
    state.selectedAgent.note[keyName].userGUID = payload.userGUID;
    state.selectedAgent.note[keyName].note = payload.note;
    state.selectedAgent.note[keyName].createTime = payload.createTime;
    state.selectedAgent.note[keyName].updateTime = payload.updateTime;
  },
  UPDATE_AGENT_SELECTED: (state, payload) => {
    state.selectedAgent.selected = payload;
  }
};

export const actions = {
  getAgentNoteType: async ({ commit }, payLoad) => {
    await fetch(
      `${Vue.prototype.$chatBaseUrl}/note/${payLoad.chatroomId}/${payLoad.type}`,
      {
        method: "GET",
        headers: new Headers(Vue.prototype.$headersChat)
      }
    )
      .then(response => {
        return response.json();
      })
      .then(result => {
        if (result.result) {
          commit("UPDATE_AGENT_NOTE", { ...result.detail });
        } else {
          console.log("error getAgentNote");
        }
      })
      .catch(error => {
        console.log(error.message);
      });
  },
  setAgentSelected(context, payload) {
    context.commit("UPDATE_AGENT_SELECTED", payload);
  }
};
export const getters = {
  getAgentNote: state => {
    return state.selectedAgent.note;
  },
  getAgentSelected: state => {
    return state.selectedAgent.selected;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
